import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from '../../assets/images/Logo-01.svg';
import { FiMenu } from '../../assets/icons/vander';

export default function Navbar({ navClass, navDark }) {
    let [navSticky, setNavSticky] = useState(false)
    let [toggleManu, setToggleManu] = useState(false)

    let location = useLocation()

    useEffect(() => {

        const scrollHandlar = () => {
            setNavSticky(window.scrollY > 50)
        }

        window.scrollTo(0, 0);

        window.addEventListener('scroll', scrollHandlar)
        return () => {
            window.removeEventListener('scroll', scrollHandlar)
        }
    }, [location.pathname])
    return (
        <nav id="navbar" className={`${navSticky ? 'nav-sticky' : ''} navbar navbar-expand-lg navbar-custom navbar-light sticky`}>
            <div className="container">
                {navDark ?
                    <Link className="navbar-brand" to="/">
                        <img src={logo} className="logo-light-mode" alt="" />
                    </Link> :
                    <Link className="navbar-brand" to="/">
                        <img src={logo} className="l-dark" alt="" />
                    </Link>
                }
                <button className="navbar-toggler" type="button" onClick={() => setToggleManu(!toggleManu)}>
                    <FiMenu className="fea icon-md" />
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse" style={{ display: toggleManu ? 'block' : 'none' }}>
                    <ul id="navbar-navlist" className={navClass}>
                        <li className="nav-item">
                            <a className="nav-link" activeClass="active" spy={true} smooth={true} duration={500}  href='#home'>Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" activeClass="active" spy={true} smooth={true} duration={500} href="#aboutUs">About Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" activeClass="active" spy={true} smooth={true} duration={500} href="#contactUs">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}