import React from "react";


function Pagenotfound() {

  return (
    <React.Fragment>
        <h1>Page Not Found</h1>
    </React.Fragment>
  );
}

export default Pagenotfound;
