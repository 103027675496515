import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/style.css'
import './assets/css/materialdesignicons.min.css'
import Index from "./page/Home";
import Pagenotfound from "./page/Pagenotfound";
import Loader from "./Components/Navbar/Loader";

function App() {
  let [loading, setLoading] = useState(true)
  useEffect(() => {
    let timeOut = setTimeout(() => setLoading(false), 600);
    return () => {
      clearTimeout(timeOut);
    };
  }, []);
  if (loading) {
      return <Loader/>
  }
  return (
    <div className="App">
       <Routes>
          <Route path="/" element={<Index/>}/>
          <Route path="*" element={<Pagenotfound/>}/>
       </Routes>
    </div>
  );
}

export default App;
