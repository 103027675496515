import React, { useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import "../Banner/banner.css";
import formImg from '../../assets/images/formImg.webp';
import emailjs from '@emailjs/browser'


export default function Contactus() {
    const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();
      emailjs
        .sendForm('service_chh2bqa', 'template_xlkm8ia', form.current, {
          publicKey: 'jHwDCvRVpbGWyc9dC',
        })
        .then(
          () => {
            // alert('SUCCESS!');
            toast.success('Successfully Sent!')
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
    };
  return (
    <React.Fragment>
      <Toaster position="top-center"  reverseOrder={false} />
      <section className="contact_us padding_section" id='contactUs'>
        <div className="container">
       
          <div className="row">
            <div className="col-md-6">
              <div className="form_img">

                <img src={formImg} alt="" />
              </div>

            </div>
            <div className="col-md-6">
              <form ref={form} onSubmit={sendEmail}>
                <div className="row form_row">
                  <div className="col-md-12">
                  <div className="content">
                <h3 className="section_title social">
                  Contact <span>Us</span>
                </h3>
               
              </div>
                  </div>

                    <div className='col-sm-12 col-md-6 col-lg-6'>
                        <div className='form-group  mb-3'>
                            <label for='First Name' className='mb-1'>First Name</label>
                            <input type="text" className='form-control shadow-none' name="first_name"/>
                        </div>
                    </div>
                    
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                        <div className='form-group  mb-3'>
                            <label className='mb-1' for='Last Name'>Last Name</label>
                            <input type="text" className='form-control shadow-none' name="last_name"/>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                        <div className='form-group  mb-3'>
                            <label className='mb-1' for='Phone'>Phone</label>
                            <input type="text" className='form-control shadow-none' name="phone"/>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                        <div className='form-group  mb-3'>
                            <label className='mb-1' for='E-Mail'>E-Mail</label>
                            <input type="text" className='form-control shadow-none' name="user_email"/>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='form-group  mb-3'>
                            <label className='mb-1' for='Write Message Here'>Write Message Here</label>
                            <textarea name="message" className='form-control shadow-none' rows='5' />
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12 text-center'>
                          <button className="submit_btn btn btn-primary" type="submit">Send</button>
                    </div>







                  {/* <div className="col-md-6">
                    <div>
                      <label htmlFor="name">First Name</label>
                      <input type="text" name="first_name"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="F-name">Last Name</label>
                      <input type="text" name="last_name"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="phone">Phone</label>
                      <input type="tel" name="phone"/>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <label htmlFor="mail">E-Mail</label>
                      <input type="email" name="user_email"/>
                    </div>

                  </div> */}
                  {/* <div className="col-md-12">
                    <div>
                      <label htmlFor="message">Write Message Here</label>
                      <textarea name="message" rows={10} />
                    </div>
                    <div className="col-md-12 button">
                      <a className="submit_btn" type="submit" value="Send" href="#">Submit</a>
                      <input className="submit_btn" type="submit" value="Send" />
                    </div>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
