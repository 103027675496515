import React from "react";

import Navbar from "../../Components/Navbar/Navbar";
import Banner from "../../Components/Banner/banner";
import ScrollTop from "../../Components/Navbar/scrollTop";
import Footer from "../../Components/Footer/BootomFooter";
import Contactus from "../../Components/Contactus/contactus";
import '../Home/home.css';



export default function Index() {
    return (
        <>
            <Navbar navClass="navbar-nav navbar-nav-link" socialClass="list-unstyled mb-0 mt-2 mt-sm-0 social-icon" navDark={true} />
            <Banner />
            <Contactus/>
            <Footer />
            <ScrollTop />
        </>
    )
}