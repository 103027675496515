import React from "react";
// import { Link } from "react-router-dom";
// import footerImg from '../../assets/images/banner-img.webp'
import { HiOutlineMail } from "react-icons/hi";
export default function BootomFooter() {
    return (
        <React.Fragment>
            <footer>
                <div className="container">

                    <div className="row">
                    <div className="col-md-6">
                            <ul>
                                <li className="d-flex">
                                    <div className="icon">
                                        <span><HiOutlineMail /></span>
                                    </div>
                                    <div className="address">
                                        <span><a href="https://www.google.com/maps/search/Aligned+Energy+Pte+Ltd.+21+Tan+Quee+Lan+Street,+02-04+Heritage+Place,+188108+Singapore/@1.2985859,103.8538563,17z/data=!3m1!4b1?entry=ttu">contact@alignedenergy.sg</a></span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 link-col">
                            <div className="copyright">                                
                                    <span>2024 © Copyrights Aligned Energy</span>                            
                            </div>

                        </div>
                       
                    </div>

                </div>

            </footer>
        </React.Fragment>
    )
}