import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./banner.css";
import videoposter from "../../assets/images/poster.jpg";
import sectionImg from "../../assets/images/1.jpg";
import plant1 from "../../assets/images/plant.png";
import bannerVideo from "../../assets/images/banner.mp4";
import sorghumBanner from "../../assets/images/sorghumplant.jpg";
import oil2 from "../../assets/images/oil-2.webp";
import logo2 from '../../assets/images/white_logo.png';


export default function SliderBanner() {
  gsap.registerPlugin(ScrollTrigger);

  const revealRefs = useRef([]);
  revealRefs.current = [];

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  useEffect(() => {
    revealRefs.current.forEach((container) => {
      const image = container.querySelector("img");
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          start: "top bottom", // Adjust as needed
          end: "bottom top", // Adjust as needed
          scrub: true,
          toggleActions: "play none none reset",
        },
      });

      tl.set(container, { autoAlpha: 1 });
      tl.from(container, {
        y: 100, // Slide container from 100px down
        duration: 1.5,
        ease: "power2.out",
      });
      tl.from(image, {
        y: -100, // Slide image from 100px up
        scale: 1.3,
        duration: 1.5,
        delay: -1.5,
        ease: "power2.out",
      });
    });
  }, []);

  return (
    <React.Fragment>
      <section className="banner" id="home">
        <video autoPlay muted loop poster={videoposter}>
          <source src={bannerVideo} type="video/mp4" />
        </video>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner-title">
                {/* <h1 className="title_text">Aligned Energy</h1> */}
                <div className="logo mb-4">
                  <img src={logo2} className="logo-light-mode" alt="" />
                </div>

                <p>
                  established in 2024, is a visionary Singaporean enterprise dedicated to pioneering sustainable solutions in the energy sector. Our mission revolves around producing Green Diesel, Green Naphtha and other Green fuels and Derivatives through innovative biomass cultivation and advanced processing technologies.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sustain">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="content">
                <h3 className="section_title commit">
                  Commitment <span>to Sustainability</span>
                </h3>
                <p>
                  At Aligned Energy, we recognize the need to transition towards
                  sustainable energy sources. Our approach starts with
                  cultivating Sweet Sorghum, a fast-growing biomass that
                  efficiently captures carbon dioxide from the atmosphere. By
                  harnessing this natural process, we not only produce
                  high-energy biofuels but also mitigate carbon emissions,
                  contributing to a healthier planet.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box reveal-container" ref={addToRefs}>
                <img src={sectionImg} alt="" className="reveal" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="circular section_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="img-box reveal-container" ref={addToRefs}>
                <img src={plant1} alt="Reveal" className="reveal" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="content">
                <h3 className="section_title circular">
                  Circular <span>Economy Principles</span>
                </h3>
                <p>
                Central to our operations is a commitment to zero waste. Through our integrated production systems, every component of the biomass is utilized and transformed into green products: Intermediate Sugar Content at 45 BRIX (ISC45) for ethanol production, Potash as fertilizer, Green (bio)Naphtha, and Green Diesel. This holistic approach not only minimizes environmental impact but also enhances resource efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Social">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="content">
                <h3 className="section_title social">
                  Social <span>Responsibility</span>
                </h3>
                <p>
                  In Bintan, Indonesia, our inaugural projects not only leads the way towards activating marginal and revitalizing bauxite mining sites, but it also supports local communities through job creation and economic growth. By aiming at transforming depleted land into productive cultivation sites, we demonstrate our dedication to environmental stewardship and community development.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box reveal-container" ref={addToRefs}>
                <img src={oil2} alt="Reveal" className="reveal" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="explore" id="aboutUs">
        <div className="img-box">
          <img src={sorghumBanner} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                Our journey at Aligned Energy is guided by a profound belief in
                the power of innovation and sustainability. By aligning economic
                prosperity with environmental and social responsibility, we
                aspire to lead the way towards a greener, more resilient future
                for generations to come.
              </p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
